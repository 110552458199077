import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllPrompts from 'redux/actions/Common/getAllPrompts';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import updatePrompt from 'redux/actions/Common/updatePrompt';
import createNewPrompt from 'redux/actions/Common/createNewPrompt';
import deletePrompt from 'redux/actions/Common/deletePrompt';
import { capitalizeText } from 'utils';
import makePromptAsDefaultPrompt from 'redux/actions/Common/makePromptAsDefaultPrompt';
import getPromptPreview from 'redux/actions/Common/getPromptPreview';
import getBotsByPromptId from 'redux/actions/Common/getBotsByPromptId';
import updateBotsPrompt from 'redux/actions/Common/updateBotsPrompt';
import getJSONProperties from 'redux/actions/Common/getJSONProperties';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Prompts = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    prompts,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allPrompts);
  const [sections, setSections] = useState([]);

  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [promptTitle, setPromptTitle] = useState('');
  const [promptSections, setPromptSections] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placeholder, setPlaceholder] = useState({});
  const [responseType, setResponseType] = useState('string');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [promptType, setPromptType] = useState('conversation');

  const [showPromptPreview, setShowPromptPreview] = useState('');
  const [showPromptPreviewFor, setShowPromptPreviewFor] = useState('');
  const [
    showConfirmPromptUpdateDialog,
    setShowConfirmPromptUpdateDialog,
  ] = useState(null);

  const [botsByPromptId, setBotsByPromptId] = useState([]);

  const [jsonProperties, setJsonProperties] = useState([]);
  const [gettingJSONProperties, setGettingJSONProperties] = useState(false);

  const handleAddSectionClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        prompts &&
        prompts.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllPrompts(promptType, 15, lastTime, () => {}));
    }
  };

  useEffect(() => {
    if (
      (showConfirmPromptUpdateDialog || showConfirmDeleteDialog) &&
      promptType === 'conversation'
    ) {
      dispatch(
        getBotsByPromptId(
          showConfirmPromptUpdateDialog || showConfirmDeleteDialog,
          (data) => {
            console.log(data);
            setBotsByPromptId(data || []);
          },
        ),
      );
    }
  }, [showConfirmPromptUpdateDialog, showConfirmDeleteDialog]);

  useEffect(() => {
    if (selectedPrompt && selectedPrompt !== 'create') {
      setPromptSections(
        prompts?.find((pr) => pr?.id === selectedPrompt)?.promptSections,
      );
      setPromptTitle(prompts?.find((pr) => pr?.id === selectedPrompt)?.title);
      setResponseType(
        prompts?.find((pr) => pr?.id === selectedPrompt)?.responseType ||
          'string',
      );
      setJsonProperties(
        prompts?.find((pr) => pr?.id === selectedPrompt)?.jsonConfig
          ?.propertyConfigs || [],
      );
    } else {
      setPromptSections([]);
      setPromptTitle('');
      setJsonProperties([]);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    dispatch(getAllPrompts(promptType, 15, null, () => {}));
    if (promptType === 'conversation') {
      setSections([
        'User_Information_Section',
        'AI_Anonymity',
        'Basic_Conversation_Instructions',
        'Personality_Description',
        'Company_Details',
        'Recent_Market_Changes_Information',
        'Availability_for_Meetings',
        'Objections',
        'Customer_Challenges',
        'Verbosity',
        'VERTICAL',
        'DESIGNATION',
        'COMPETITORS',
      ]);
    } else if (promptType === 'call_analysis_method') {
      setSections(['sales_call_analysis_method']);
    } else if (promptType === 'call_analysis_feedback') {
      setSections(['sales_call_feedback']);
    } else if (promptType === 'playbook_analysis_for_inbound_call') {
      setSections(['sales_call_feedback']);
    } else if (promptType === 'playbook_analysis_for_outbound_call') {
      setSections(['sales_call_feedback']);
    } else if (promptType === 'call_analysis_feedback_for_otpc') {
      setSections(['sales_call_feedback']);
    } else if (promptType === 'call_summary') {
      setSections(['sales_call_summary']);
    } else if (promptType === 'io_bond_call_summary') {
      setSections(['sales_call_summary']);
    } else if (promptType === 'numeric_discussion_prompt') {
      setSections(['numeric_discussion_section']);
    } else if (promptType === 'ask_ai') {
      setSections(['Basic_Conversation_Instructions']);
    } else if (
      promptType === 'call_analysis_method_spin' ||
      promptType === 'call_analysis_method_spiced' ||
      promptType === 'call_analysis_method_meddpic' ||
      promptType === 'call_analysis_method_bant' ||
      promptType === 'call_analysis_method_challenger' ||
      promptType === 'call_analysis_method_star'
    ) {
      setSections(['sales_call_analysis_method']);
    } else if (
      promptType === 'playbook_prompt' ||
      promptType === 'custom_playbook_prompt'
    ) {
      setSections([
        'playbook_basic_instruction',
        'playbook_product_list',
        'playbook_output_instruction',
      ]);
    } else if (promptType === 'context_query_prompt') {
      setSections(['context_query']);
    } else if (promptType === 'content_scoring_prompt') {
      setSections(['content_scoring_section']);
    } else if (promptType === 'content_scoring_prompt_inbound') {
      setSections(['content_scoring_section']);
    } else if (promptType === 'content_scoring_prompt_outbound') {
      setSections(['content_scoring_section']);
    } else if (promptType === 'preset_question_prompt') {
      setSections(['general instruction', 'ai anonymity', 'json instruction']);
    } else if (promptType === 'speaker_detection_prompt') {
      setSections(['speaker_detection_instruction']);
    } else if (promptType === 'hubspot_property_prompt') {
      setSections(['general instruction', 'json instruction']);
    } else if (promptType === 'model_fine_tuning_text_and_question') {
      setSections(['main_section', 'json instruction']);
    } else {
      setSections([]);
    }
  }, [promptType]);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
        >
          {selectedPrompt ? (
            <Box>
              <Box p={1} display={'flex'} alignItems={'center'} gap={2}>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  onClick={() => {
                    setSelectedPrompt('');
                  }}
                  color="primary"
                  size="small"
                >
                  Go Back
                </Button>
              </Box>
              <Box
                sx={{
                  maxWidth: 700,
                  margin: '0 auto',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      // sx={{ marginBottom: 2 }}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Prompt Type
                      </FormLabel>
                      <Select
                        // ref={promptSelectRef}
                        labelId="prompt-select-label"
                        // label="Select Prompt"
                        value={promptType || ''}
                        onChange={(e) => {
                          setPromptType(e.target.value);
                        }}
                      >
                        <MenuItem value={'conversation'}>Conversation</MenuItem>
                        <MenuItem value={'call_analysis_method'}>
                          Methodologies
                        </MenuItem>
                        <MenuItem value={'call_analysis_feedback'}>
                          Playbook
                        </MenuItem>
                        <MenuItem value={'playbook_analysis_for_inbound_call'}>
                          Playbook inbound
                        </MenuItem>
                        <MenuItem value={'playbook_analysis_for_outbound_call'}>
                          Playbook outbound
                        </MenuItem>
                        {/* <MenuItem value={'call_summary'}>Call Summary</MenuItem> */}
                        <MenuItem value={'io_bond_call_summary'}>
                          Call Summary Inbound/Outbound
                        </MenuItem>
                        <MenuItem value={'call_analysis_feedback_for_otpc'}>
                          Playbook feedback for otpc
                        </MenuItem>
                        <MenuItem value={'playbook_prompt'}>
                          Playbook generation prompt
                        </MenuItem>
                        <MenuItem value={'numeric_discussion_prompt'}>
                          Numeric Discussion
                        </MenuItem>
                        <MenuItem value={'ask_ai'}>ASK AI</MenuItem>
                        <MenuItem value={'call_analysis_method_spin'}>
                          SPIN Method
                        </MenuItem>
                        <MenuItem value={'call_analysis_method_spiced'}>
                          SPICED Method
                        </MenuItem>
                        <MenuItem value={'call_analysis_method_meddpic'}>
                          MEDDPIC Method
                        </MenuItem>
                        <MenuItem value={'call_analysis_method_bant'}>
                          BANT Method
                        </MenuItem>
                        <MenuItem value={'call_analysis_method_challenger'}>
                          CHALLENGER Method
                        </MenuItem>
                        <MenuItem value={'call_analysis_method_star'}>
                          STAR Method
                        </MenuItem>
                        <MenuItem value={'context_query_prompt'}>
                          Context Query
                        </MenuItem>
                        <MenuItem value={'content_scoring_prompt'}>
                          Content Scoring
                        </MenuItem>
                        <MenuItem value={'content_scoring_prompt_inbound'}>
                          Content Scoring Inbound
                        </MenuItem>
                        <MenuItem value={'content_scoring_prompt_outbound'}>
                          Content Scoring Outbound
                        </MenuItem>
                        <MenuItem value={'preset_question_prompt'}>
                          Preset Questions
                        </MenuItem>
                        <MenuItem value={'custom_playbook_prompt'}>
                          Custom Playbook Prompt
                        </MenuItem>
                        <MenuItem value={'speaker_detection_prompt'}>
                          Speaker Detection Prompt
                        </MenuItem>
                        <MenuItem value={'hubspot_property_prompt'}>
                          Hubspot Property Prompt
                        </MenuItem>
                        <MenuItem value={'model_fine_tuning_text_and_question'}>
                          Model Fine Tuning Text and Question
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Title
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Title"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="title"
                      value={promptTitle}
                      onChange={(e) => setPromptTitle(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      // sx={{ marginBottom: 2 }}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Response Type
                      </FormLabel>
                      <Select
                        // ref={promptSelectRef}
                        labelId="prompt-select-label"
                        // label="Select Prompt"
                        value={responseType || ''}
                        onChange={(e) => {
                          setResponseType(e.target.value);
                        }}
                      >
                        <MenuItem value={'string'}>String</MenuItem>
                        <MenuItem value={'json'}>JSON</MenuItem>
                        <MenuItem value={'html'}>HTML</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {promptSections?.length === 0 ? (
                    <Grid item xs={12} textAlign={'right'}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<Add />}
                        color="primary"
                        onClick={(e) => {
                          handleAddSectionClick(e);
                        }}
                      >
                        Add Section
                      </Button>
                      <Menu
                        id="add-section-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        disableScrollLock={true}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        {sections.map((sc) => (
                          <Tooltip
                            key={sc}
                            title={
                              promptSections?.find((ps) => ps?.type === sc)
                                ? 'Section is already added'
                                : ''
                            }
                            arrow
                            placement="right"
                          >
                            <MenuItem
                              value={sc}
                              onClick={(e) => {
                                if (
                                  !promptSections?.find((ps) => ps?.type === sc)
                                ) {
                                  setPromptSections((prev) => {
                                    return [
                                      ...prev,
                                      {
                                        type: sc,
                                        text: '',
                                        placeHolders: {},
                                        optionalSection:
                                          sc === 'VERTICAL' ||
                                          sc === 'DESIGNATION' ||
                                          sc === 'COMPETITORS'
                                            ? true
                                            : false,
                                      },
                                    ];
                                  });
                                }
                                handleClose();
                              }}
                            >
                              <Typography
                                color={
                                  promptSections?.find((ps) => ps?.type === sc)
                                    ? 'text.secondary'
                                    : 'text.primary'
                                }
                              >
                                {sc}
                              </Typography>
                            </MenuItem>
                          </Tooltip>
                        ))}
                      </Menu>
                    </Grid>
                  ) : null}
                  {promptSections?.map((ps) => (
                    <>
                      <Grid item xs={12} textAlign={'right'}>
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<Add />}
                          color="primary"
                          onClick={(e) => {
                            handleAddSectionClick(e);
                          }}
                        >
                          Add Section
                        </Button>
                        <Menu
                          id="add-section-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          disableScrollLock={true}
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}
                          anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                          }}
                          style={{
                            marginTop: '15px',
                          }}
                        >
                          {sections.map((sc) => (
                            <Tooltip
                              key={sc}
                              title={
                                promptSections?.find((ps) => ps?.type === sc)
                                  ? 'Section is already added'
                                  : ''
                              }
                              arrow
                              placement="right"
                            >
                              <MenuItem
                                value={sc}
                                onClick={(e) => {
                                  if (
                                    !promptSections?.find(
                                      (ps) => ps?.type === sc,
                                    )
                                  ) {
                                    setPromptSections((prev) => {
                                      return [
                                        ...prev,
                                        {
                                          type: sc,
                                          text: '',
                                          placeHolders: {},
                                          optionalSection:
                                            sc === 'VERTICAL' ||
                                            sc === 'DESIGNATION' ||
                                            sc === 'COMPETITORS'
                                              ? true
                                              : false,
                                        },
                                      ];
                                    });
                                  }
                                  handleClose();
                                }}
                              >
                                <Typography
                                  color={
                                    promptSections?.find(
                                      (ps) => ps?.type === sc,
                                    )
                                      ? 'text.secondary'
                                      : 'text.primary'
                                  }
                                >
                                  {sc}
                                </Typography>
                              </MenuItem>
                            </Tooltip>
                          ))}
                        </Menu>
                      </Grid>
                      <Grid item xs={12} key={ps?.type}>
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            // justifyContent={'space-between'}
                            gap={1}
                          >
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                            >
                              Placeholders
                            </Typography>
                          </Box>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            // justifyContent={'space-between'}
                            gap={1}
                          >
                            <TextField
                              autoFocus
                              placeholder="placeholder"
                              variant="outlined"
                              color="primary"
                              size="small"
                              name="placeholder"
                              value={placeholder?.[ps?.type]}
                              onChange={(e) =>
                                setPlaceholder((prev) => {
                                  return {
                                    ...prev,
                                    [ps?.type]: e.target.value,
                                  };
                                })
                              }
                              // fullWidth
                            />
                            <Button
                              variant="text"
                              size="small"
                              startIcon={<Add />}
                              onClick={() => {
                                if (placeholder?.[ps?.type]?.trim()) {
                                  const updatedPlaceholdersArr = [
                                    ...Object.keys(ps?.placeHolders || {}),
                                    placeholder?.[ps?.type],
                                  ];
                                  const updatedPromptSections = [
                                    ...promptSections,
                                  ];
                                  setPromptSections((prev) => {
                                    const map = {};
                                    updatedPlaceholdersArr.map(
                                      (uph) => (map[uph] = ''),
                                    );
                                    // console.log(map);
                                    return updatedPromptSections?.map((ups) => {
                                      if (ups.type === ps.type) {
                                        return {
                                          ...ups,
                                          placeHolders: map,
                                        };
                                      } else {
                                        return ups;
                                      }
                                    });
                                  });
                                  setPlaceholder((prev) => {
                                    return {
                                      ...prev,
                                      [ps?.type]: '',
                                    };
                                  });
                                }
                              }}
                            >
                              Add placeholder
                            </Button>
                          </Box>
                          {ps?.placeHolders &&
                          Object.keys(ps?.placeHolders) &&
                          Array.isArray(Object.keys(ps?.placeHolders)) &&
                          Object.keys(ps?.placeHolders)?.length > 0 ? (
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={1}
                              flexWrap={'wrap'}
                            >
                              {Object.keys(ps?.placeHolders)?.map(
                                (ph, index) => (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexWrap={'wrap'}
                                  >
                                    <Chip
                                      key={ph}
                                      label={ph}
                                      size="medium"
                                      color="primary"
                                    />
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Remove"
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          const updatedPlaceholdersArr = [
                                            ...Object.keys(ps?.placeHolders),
                                          ];
                                          const updatedPromptSections = [
                                            ...promptSections,
                                          ];
                                          setPromptSections((prev) => {
                                            const map = {};
                                            updatedPlaceholdersArr
                                              .filter((ups) => ups !== ph)
                                              .map((uph) => (map[uph] = ''));
                                            // console.log(map);
                                            return updatedPromptSections?.map(
                                              (ups) => {
                                                if (ups.type === ps.type) {
                                                  return {
                                                    ...ups,
                                                    placeHolders: map,
                                                  };
                                                } else {
                                                  return ups;
                                                }
                                              },
                                            );
                                          });
                                        }}
                                      >
                                        <Delete sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                ),
                              )}
                            </Box>
                          ) : null}
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{
                              background: '#f1f1f1',
                              padding: '8px 16px',
                            }}
                          >
                            <Typography color={'text.secondary'}>
                              {ps?.type}
                            </Typography>
                            <FormControl>
                              <Select
                                size="small"
                                labelId="country-select-label"
                                id="country-select"
                                name="country"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  const updatedPromptSections = promptSections?.map(
                                    (ups) => {
                                      if (ups?.type === ps?.type) {
                                        return {
                                          ...ups,
                                          optionalSection: e.target.value,
                                        };
                                      } else return ups;
                                    },
                                  );
                                  setPromptSections(updatedPromptSections);
                                }}
                                value={ps?.optionalSection}
                              >
                                <MenuItem value={true}>Optional</MenuItem>
                                <MenuItem value={false}>Mandatory</MenuItem>
                              </Select>
                            </FormControl>
                            <Button
                              variant="text"
                              onClick={() => {
                                setPromptSections((prev) =>
                                  prev.filter(
                                    (prevps) => prevps?.type !== ps?.type,
                                  ),
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                            >
                              Section Prompt
                            </Typography>
                            <TextField
                              autoFocus
                              placeholder="Section Prompt"
                              variant="outlined"
                              color="primary"
                              size="small"
                              multiline
                              rows={7}
                              name="sectionPrompt"
                              value={ps?.text}
                              onChange={(e) => {
                                const updatedPromptSections = promptSections?.map(
                                  (ups) => {
                                    if (ups?.type === ps?.type) {
                                      return {
                                        ...ups,
                                        text: e.target.value,
                                      };
                                    } else return ups;
                                  },
                                );
                                setPromptSections(updatedPromptSections);
                              }}
                              fullWidth
                            />
                            {promptType === 'io_bond_call_summary' ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      setGettingJSONProperties(true);
                                      dispatch(
                                        getJSONProperties(
                                          ps,
                                          (data) => {
                                            setGettingJSONProperties(false);
                                            console.log(data);
                                            setJsonProperties(
                                              data?.propertyConfigs?.map(
                                                (pc) => {
                                                  return {
                                                    ...pc,
                                                    title: pc?.propertyName
                                                      ?.split('_')
                                                      ?.map((w) =>
                                                        capitalizeText(w),
                                                      )
                                                      ?.join(' '),
                                                  };
                                                },
                                              ),
                                            );
                                          },
                                          () => {
                                            setGettingJSONProperties(false);
                                          },
                                        ),
                                      );
                                    }}
                                    disabled={gettingJSONProperties}
                                    startIcon={
                                      gettingJSONProperties ? (
                                        <CircularProgress size={20} />
                                      ) : null
                                    }
                                  >
                                    Get JSON Properties
                                  </Button>
                                </Box>
                                <Box display={'flex'} flexDirection={'column'}>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    fontWeight={'bold'}
                                  >
                                    Extracted JSON Properties
                                  </Typography>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography>Property</Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography>Title</Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography>Type</Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography>Fallback Text</Typography>
                                      </Grid>
                                    </Grid>
                                    {jsonProperties.map((jsonProp) => (
                                      <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                          <Chip
                                            label={jsonProp?.propertyName}
                                            color="primary"
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <TextField
                                            placeholder="Property Title"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            name="propertyTitle"
                                            value={jsonProp?.title || ''}
                                            onChange={(e) => {
                                              const updatedJsonProperties = jsonProperties?.map(
                                                (jp) => {
                                                  if (
                                                    jp?.propertyName ===
                                                    jsonProp?.propertyName
                                                  ) {
                                                    return {
                                                      ...jp,
                                                      title: e.target.value,
                                                    };
                                                  } else return jp;
                                                },
                                              );
                                              setJsonProperties(
                                                updatedJsonProperties,
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <FormControl
                                            variant="outlined"
                                            size="small"
                                          >
                                            <Select
                                              // ref={promptSelectRef}
                                              labelId="property-type-select-label"
                                              // label="Select Prompt"
                                              value={jsonProp?.type || ''}
                                              onChange={(e) => {
                                                const updatedJsonProperties = jsonProperties?.map(
                                                  (jp) => {
                                                    if (
                                                      jp?.propertyName ===
                                                      jsonProp?.propertyName
                                                    ) {
                                                      return {
                                                        ...jp,
                                                        type: e.target.value,
                                                      };
                                                    } else return jp;
                                                  },
                                                );
                                                setJsonProperties(
                                                  updatedJsonProperties,
                                                );
                                              }}
                                            >
                                              <MenuItem value={'list<string>'}>
                                                List of Strings
                                              </MenuItem>
                                              <MenuItem value={'list<map>'}>
                                                List of Objects
                                              </MenuItem>
                                              <MenuItem value={'string'}>
                                                String
                                              </MenuItem>
                                              <MenuItem value={'map'}>
                                                Object
                                              </MenuItem>
                                              <MenuItem value={'number'}>
                                                Number
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <TextField
                                            autoFocus
                                            placeholder="Fallback Text"
                                            variant="outlined"
                                            color="primary"
                                            multiline
                                            rows={3}
                                            size="small"
                                            name="fallbackText"
                                            value={jsonProp?.fallbackText || ''}
                                            onChange={(e) => {
                                              const updatedJsonProperties = jsonProperties?.map(
                                                (jp) => {
                                                  if (
                                                    jp?.propertyName ===
                                                    jsonProp?.propertyName
                                                  ) {
                                                    return {
                                                      ...jp,
                                                      fallbackText:
                                                        e.target.value,
                                                    };
                                                  } else return jp;
                                                },
                                              );
                                              setJsonProperties(
                                                updatedJsonProperties,
                                              );
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Box>
                                </Box>
                              </Box>
                            ) : null}
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                            >
                              Section Default Prompt (will be used in case any
                              of the placeholder value is not coming in request)
                            </Typography>
                            <TextField
                              autoFocus
                              placeholder="Section Default Prompt"
                              variant="outlined"
                              color="primary"
                              size="small"
                              multiline
                              rows={4}
                              sx={{ mt: 1 }}
                              name="sectionDefaultPrompt"
                              value={ps?.defaultText || ''}
                              onChange={(e) => {
                                const updatedPromptSections = promptSections?.map(
                                  (ups) => {
                                    if (ups?.type === ps?.type) {
                                      return {
                                        ...ups,
                                        defaultText: e.target.value,
                                      };
                                    } else return ups;
                                  },
                                );
                                setPromptSections(updatedPromptSections);
                              }}
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                      gap={1}
                    >
                      {selectedPrompt !== 'create' ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            if (promptType === 'conversation') {
                              setShowConfirmPromptUpdateDialog(selectedPrompt);
                            } else {
                              dispatch(
                                updatePrompt(
                                  selectedPrompt,
                                  {
                                    description: '',
                                    promptSections: promptSections,
                                    title: promptTitle,
                                    responseType,
                                    promptType: promptType,
                                    jsonConfig:
                                      promptType === 'io_bond_call_summary'
                                        ? {
                                            propertyConfigs: jsonProperties,
                                          }
                                        : null,
                                  },
                                  (data) => {
                                    setSelectedPrompt('');
                                    console.log('update respones', data);
                                    setShowConfirmPromptUpdateDialog(null);
                                  },
                                ),
                              );
                            }
                          }}
                        >
                          Update Prompt
                        </Button>
                      ) : null}
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          dispatch(
                            createNewPrompt(
                              {
                                description: '',
                                promptSections: promptSections,
                                title: promptTitle,
                                promptType: promptType,
                                responseType,
                                jsonConfig:
                                  promptType === 'io_bond_call_summary'
                                    ? {
                                        propertyConfigs: jsonProperties,
                                      }
                                    : null,
                              },
                              (data) => {
                                dispatch(
                                  getAllPrompts(promptType, 15, null, () => {
                                    console.log('create prompt respones', data);
                                    setSelectedPrompt('');
                                  }),
                                );
                              },
                            ),
                          );
                        }}
                      >
                        Create New Prompt
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ marginBottom: 2, width: 250 }}
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Prompt Type
                </FormLabel>
                <Select
                  // ref={promptSelectRef}
                  labelId="prompt-select-label"
                  // label="Select Prompt"
                  value={promptType || ''}
                  onChange={(e) => {
                    setPromptType(e.target.value);
                  }}
                >
                  <MenuItem value={'conversation'}>Conversation</MenuItem>
                  <MenuItem value={'call_analysis_method'}>
                    Methodologies
                  </MenuItem>
                  <MenuItem value={'call_analysis_feedback'}>Playbook</MenuItem>
                  <MenuItem value={'playbook_analysis_for_inbound_call'}>
                    Playbook inbound
                  </MenuItem>
                  <MenuItem value={'playbook_analysis_for_outbound_call'}>
                    Playbook outbound
                  </MenuItem>
                  {/* <MenuItem value={'call_summary'}>Call Summary</MenuItem> */}
                  <MenuItem value={'io_bond_call_summary'}>
                    Call Summary Inbound/Outbound
                  </MenuItem>
                  <MenuItem value={'call_analysis_feedback_for_otpc'}>
                    Playbook feedback for otpc
                  </MenuItem>
                  <MenuItem value={'playbook_prompt'}>
                    Playbook generation prompt
                  </MenuItem>
                  <MenuItem value={'numeric_discussion_prompt'}>
                    Numeric Discussion
                  </MenuItem>
                  <MenuItem value={'ask_ai'}>ASK AI</MenuItem>
                  <MenuItem value={'call_analysis_method_spin'}>
                    SPIN Method
                  </MenuItem>
                  <MenuItem value={'call_analysis_method_spiced'}>
                    SPICED Method
                  </MenuItem>
                  <MenuItem value={'call_analysis_method_meddpic'}>
                    MEDDPIC Method
                  </MenuItem>
                  <MenuItem value={'call_analysis_method_bant'}>
                    BANT Method
                  </MenuItem>
                  <MenuItem value={'call_analysis_method_challenger'}>
                    CHALLENGER Method
                  </MenuItem>
                  <MenuItem value={'call_analysis_method_star'}>
                    STAR Method
                  </MenuItem>
                  <MenuItem value={'context_query_prompt'}>
                    Context Query
                  </MenuItem>
                  <MenuItem value={'content_scoring_prompt'}>
                    Content Scoring
                  </MenuItem>
                  <MenuItem value={'content_scoring_prompt_inbound'}>
                    Content Scoring Inbound
                  </MenuItem>
                  <MenuItem value={'content_scoring_prompt_outbound'}>
                    Content Scoring Outbound
                  </MenuItem>
                  <MenuItem value={'preset_question_prompt'}>
                    Preset Questions
                  </MenuItem>
                  <MenuItem value={'custom_playbook_prompt'}>
                    Custom Playbook Prompt
                  </MenuItem>
                  <MenuItem value={'speaker_detection_prompt'}>
                    Speaker Detection Prompt
                  </MenuItem>
                  <MenuItem value={'hubspot_property_prompt'}>
                    Hubspot Property Prompt
                  </MenuItem>
                  <MenuItem value={'model_fine_tuning_text_and_question'}>
                    Model Fine Tuning Text and Question
                  </MenuItem>
                </Select>
              </FormControl>
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: 'calc(100vh - 200px)',
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                }}
                onScroll={handleListScroll}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          ID
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          TITLE
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          TYPE
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                          align="center"
                        >
                          DATE
                        </Typography>
                      </TableCell>
                      {promptType !== 'conversation' ? (
                        <TableCell align="center">
                          <Typography
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                            align="center"
                          >
                            Default
                          </Typography>
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                          align="center"
                        >
                          ACTIONS
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prompts.map((prompt, i) => (
                      <TableRow
                        key={i}
                        // sx={{
                        //   '&:last-child td, &:last-child th': {
                        //     border: 0,
                        //   },
                        // }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant={'subtitle2'} fontWeight={700}>
                            {prompt?.id}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant={'subtitle2'} fontWeight={700}>
                            {prompt?.title}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant={'subtitle2'} fontWeight={700}>
                            {capitalizeText(
                              prompt?.promptType?.split('_').join(' '),
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant={'caption'}
                            // fontWeight={700}
                            // sx={{
                            //   color: theme.palette.success.main,
                            // }}
                          >
                            {moment(prompt?.createdOn).format('DD MMMM YYYY')}
                          </Typography>
                        </TableCell>
                        {promptType !== 'conversation' ? (
                          <TableCell align="center">
                            <Tooltip
                              arrow
                              placement="top"
                              title={'Make this prompt default prompt'}
                            >
                              {prompt?.customFields?.selected ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled
                                  onClick={() => {}}
                                >
                                  Default
                                </Button>
                              ) : (
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={(e) => {
                                    dispatch(
                                      makePromptAsDefaultPrompt(
                                        prompt?.id,
                                        promptType,
                                        () => {
                                          alert('Default Prompt updated');
                                          dispatch(
                                            getAllPrompts(
                                              promptType,
                                              15,
                                              null,
                                              () => {},
                                            ),
                                          );
                                        },
                                      ),
                                    );
                                  }}
                                >
                                  Make it Default
                                </Button>
                              )}
                            </Tooltip>
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Tooltip
                              title={'Preview Prompt'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  dispatch(
                                    getPromptPreview(
                                      {
                                        description: prompt?.description,
                                        promptSections: prompt?.promptSections,
                                        promptType: prompt?.promptType,
                                        title: prompt?.title,
                                      },
                                      (promptPreview) => {
                                        console.log(promptPreview);
                                        setShowPromptPreviewFor(prompt?.title);
                                        setShowPromptPreview(promptPreview);
                                      },
                                    ),
                                  );
                                }}
                              >
                                <Assignment color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Edit'} placement="top" arrow>
                              <IconButton
                                onClick={() => {
                                  setSelectedPrompt(prompt?.id);
                                }}
                              >
                                <Edit
                                  color={
                                    prompt.transcript ? 'primary' : 'default'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Delete'} placement="top" arrow>
                              <IconButton
                                onClick={() => {
                                  setShowConfirmDeleteDialog(prompt?.id);
                                }}
                              >
                                <Delete color={'default'} />
                              </IconButton>
                              {/* )} */}
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        variant="footer"
                        colSpan={6}
                        sx={{
                          padding: 0,
                        }}
                      >
                        {fetchingPagination ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: 1,
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={handleListScroll}
                              disabled
                            >
                              <Typography color="textPrimary">
                                Loading more prompts ...{' '}
                              </Typography>
                              <CircularProgress size={20} />
                            </Button>
                          </Box>
                        ) : emptyList === false && prompts.length >= 10 ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: 1,
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={(e) => handleListScroll(e, true)}
                            >
                              Load More
                            </Button>
                          </Box>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
          {!selectedPrompt ? (
            <Tooltip arrow placement="top" title="Create New">
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 30, right: 30 }}
                onClick={() => setSelectedPrompt('create')}
              >
                <Add />
              </Fab>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPromptPreview)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPromptPreview('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              {showPromptPreviewFor}(Prompt Preview)
            </Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {showPromptPreview}
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              {botsByPromptId &&
              botsByPromptId?.length > 0 &&
              promptType === 'conversation'
                ? 'Following bots are using this prompt. Are you sure you want to delete this prompt?'
                : 'Are you sure you want to delete this prompt?'}
            </DialogContentText>
            {botsByPromptId &&
            botsByPromptId?.length > 0 &&
            promptType === 'conversation' ? (
              <Box gap={1} display={'flex'} flexDirection={'column'}>
                {botsByPromptId?.map((bot) => {
                  return (
                    <Box
                      key={bot?.id}
                      flex={1}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        border: '1px solid #d3d3d3',
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body1" color={'primary.main'}>
                        {bot?.title}
                      </Typography>
                      {bot?.description ? (
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          {bot?.description}
                        </Typography>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                deletePrompt(showConfirmDeleteDialog, () => {
                  dispatch({
                    type: 'UPDATE_PROMPTS_AFTER_DELETE',
                    payload: {
                      id: showConfirmDeleteDialog,
                    },
                  });
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmPromptUpdateDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              {botsByPromptId && botsByPromptId?.length > 0
                ? 'Following bots are using this prompt. Updating this prompt will also update these bots.'
                : 'Are you sure you want to update this prompt?'}
            </DialogContentText>
            {botsByPromptId && botsByPromptId?.length > 0 ? (
              <Box gap={1} display={'flex'} flexDirection={'column'}>
                {botsByPromptId?.map((bot) => {
                  return (
                    <Box
                      key={bot?.id}
                      flex={1}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        border: '1px solid #d3d3d3',
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body1" color={'primary.main'}>
                        {bot?.title}
                      </Typography>
                      {bot?.description ? (
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          {bot?.description}
                        </Typography>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmPromptUpdateDialog(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              if (botsByPromptId && botsByPromptId?.length > 0) {
                dispatch(
                  updateBotsPrompt(
                    {
                      bots: botsByPromptId?.map((bot) => {
                        return bot?.id;
                      }),
                      promptId: showConfirmPromptUpdateDialog,
                      promptSections: promptSections,
                    },
                    () => {
                      dispatch(
                        updatePrompt(
                          selectedPrompt,
                          {
                            description: '',
                            promptSections: promptSections,
                            title: promptTitle,
                            responseType,
                            promptType: promptType,
                          },
                          (data) => {
                            setSelectedPrompt('');
                            console.log('update respones', data);
                            setShowConfirmPromptUpdateDialog(null);
                          },
                        ),
                      );
                    },
                  ),
                );
              } else {
                dispatch(
                  updatePrompt(
                    selectedPrompt,
                    {
                      description: '',
                      promptSections: promptSections,
                      title: promptTitle,
                      responseType,
                      promptType: promptType,
                    },
                    (data) => {
                      setSelectedPrompt('');
                      console.log('update respones', data);
                      setShowConfirmPromptUpdateDialog(null);
                    },
                  ),
                );
              }
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fixed>
  );
};

export default Prompts;
